.redirect-page {
    display: flex;
}

.redirect-panel {
    display: flex;
    width: 40%;
    min-height: 100vh;
    align-items: center;
    padding: 6rem;
    background-color: #202020;
    color: rgb(255, 255, 255);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.redirect-title {
    border-radius: 7px;
    margin: 0 2rem 0 1rem;
    font-size: 2.5rem;
}

.redirect-subtitle {
    border-style: solid;
    border-width: 0 0 0 0.1rem;
    padding: 1rem 0 1rem 1rem;
    font-size: 1.5rem;
}

.counter-panel {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.counter-title {
    padding: 0 0 0 1.5rem;
    display: flex;
}

.counter-dots {
    width: 1.5rem;
}

.counter-box {
    font-size: 4rem;
}

.redirect-page {
    @media screen and (max-width: 690px) {
        flex-direction: column;
    }
}

.redirect-panel {
    @media screen and (max-width: 1405px) {
        padding: 6rem 3rem;
    }

    @media screen and (max-width: 1165px) {
        width: 50%;
    }

    @media screen and (max-width: 930px) {
        padding: 6rem 1rem;
    }

    @media screen and (max-width: 690px) {
        width: 100%;
        min-height: 70vh;
        padding: 1rem 1rem;
    }
}

.redirect-title {
    @media screen and (max-width: 805px) {
        font-size: 2rem;
    }

    @media screen and (max-width: 380px) {
        margin: 0 1rem 0 1rem;
    }

    @media screen and (max-width: 340px) {
        font-size: 1.5rem;
    }
}

.redirect-subtitle {
    @media screen and (max-width: 805px) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: 340px) {
        font-size: 1rem;
    }
}

.counter-panel {
    @media screen and (max-width: 1165px) {
        width: 50%;
    }

    @media screen and (max-width: 690px) {
        width: 100%;
        min-height: 30vh;
        padding: 1rem;
    }
}
