.custom-checkbox {
    width: 16px;
    height: 16px;
    padding: 1px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}
