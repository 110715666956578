.modal-background {
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(39, 39, 39, 0.2);
}

.success-panel {
    width: 60%;
    height: 40vh;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255,255,255);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.top-half {
    width: 100%;
    text-align: right;
}

.close-button {
    cursor: pointer;
}

.bottom-half {
    width: 100%;
    padding: 1rem 3rem 3rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
}

.link-ready-title {
    font-size: 3rem;
}

.link-container {
    width: 80%;
    height: 6rem;
    padding: 1rem;
    box-sizing: border-box;
    border-style: solid;
    border-width: 3px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(233, 233, 233);
}

.link-container a {
    overflow: hidden;
    font-size: 2rem;
    color: rgb(0,0,0);
    text-decoration: none;
}

.copy-icon {
    cursor: pointer;
    transition: all 50ms linear;

    &:active {
        color:rgb(142, 142, 142);
    }
}
