.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #202020;
  color: rgb(255, 255, 255);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.header a, .header h1, .header h2 {
  padding: 1rem;
  display: inline;
}

.title {
  border-radius: 7px;
  margin: 0 2rem 0 1rem;
  font-size: 2rem;
  transition: all 200ms linear;
  
  &:hover {
    background-color:rgb(17, 17, 17);
    cursor: pointer;
  }

  &:active {
    background-color:rgb(70, 70, 70);
  }
}

.subtitle {
  border-style: solid;
  border-width: 0 0 0 0.1rem;
}

.body {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0 0 0;
  padding: 3rem;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.link-form-entry {
  width: 100%;
  margin: 0 0 2rem 0;
  display: flex;
  justify-content: left;
  align-items: center;
}

.link-input-container {
  width: 100%;
  margin: 0 0 0 3rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.link-form-label {
  white-space: nowrap;
  display: inline;
}

.link-input {
  width: 100%;
  height: 4rem;
  padding: 1rem;
  flex-grow: 1;
  box-sizing: border-box;
  border-radius: 10px;
  border-width: 1px;
  font-size: 2rem;
  font-weight: bold;
}

.link-custom-prefix {
  margin: 0 0.5rem 0 0.5rem;
  font-size: 2rem;
}

.link-custom-check-container {
  display: flex;
  align-items: center;
}

.link-custom-check-container, .link-custom-check-container > * {
  &:hover {
    cursor: pointer;
  }
}

.link-custom-check-label {
  margin: 0 0 0 0.2rem;
}

.body button {
  margin: 1rem 0 -2rem 0;
  width: 30rem;
  height: 4rem;
  color:rgb(255, 255, 255);
  background-color: #202020;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  font-size: 2rem;
  transition: all 200ms linear;
  
  &:hover {
    background-color:#444444;
    cursor: pointer;
  }

  &:active {
    background-color:#717171;
  }
}

.animate-appear {
  animation-name: appear-animation;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

.animate-appear-opacity {
  animation-name: appear-opacity-animation;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

.animate-disappear {
  animation-name: disappear-animation;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
} 

@keyframes appear-animation {
  from {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
  }
  to {
    max-height: 100vh;
    opacity: 1;
  }
}

@keyframes appear-opacity-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear-animation {
  from {
    max-height: 100vh;
    opacity: 1;
  }
  to {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
  }
}

.hidden {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}

.hidden-300 {
  animation-name: hidden-300-animation;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

@keyframes hidden-300-animation {
  0% {
    opacity: 1;
  }
  99% {
    max-height: 100vh;
  }
  100% {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
  }
}

.header a, .header h1, .header h2 {
  @media screen and (max-width: 310px) {
    padding: 0.5rem;
  }
}

.body {
  @media screen and (max-width: 390px) {
    padding: 1.5rem 1.5rem 3rem 1.5rem;
  }
}

.title {
  @media screen and (max-width: 420px) {
    margin: 0 1rem 0 1rem;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 350px) {
    margin: 0 0.5rem 0 0.5rem;
  }

  
}

.subtitle {
  @media screen and (max-width: 420px) {
    font-size: 1rem;
  }
}

.link-form-entry {
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.link-form-label {
  @media screen and (max-width: 800px) {
    font-size: 0.9rem;
  }
}

.link-input-container {
  @media screen and (max-width: 800px) {
    margin: 1rem 0 0 0;
  }
}

.link-input-container * {
  @media screen and (max-width: 1015px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 885px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 800px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 580px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 510px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.8rem;
  }
}

.link-custom-prefix {
  @media screen and (max-width: 885px) {
    margin: 0 0.2rem 0 0.5rem;
  }
  
  @media screen and (max-width: 800px) {
    margin: 0 0.2rem 0 0;
  }
}

.link-input {
  @media screen and (max-width: 1015px) {
    padding: 1rem 0.75rem;
  }

  @media screen and (max-width: 885px) {
    padding: 1rem 0.5rem;
  }

  @media screen and (max-width: 800px) {
    height: 3rem;
  }
  
  @media screen and (max-width: 580px) {
    height: 2rem;
  }

  @media screen and (max-width: 510px) {
    height: 1rem;
  }

  @media screen and (max-width: 450px) {
    padding: 1rem 0.5rem;
  }

  @media screen and (max-width: 350px) {
    padding: 1rem 0.25rem;
    border-radius: 5px;
  }
}

.link-custom-check-label {
  @media screen and (max-width: 510px) {
    font-size: 0.75rem;
  }
}

.body button {
  @media screen and (max-width: 630px) {
    width: 100%;
  }

  @media screen and (max-width: 510px) {
    height: 2rem;
    font-size: 1rem;
  }
}
